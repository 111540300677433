import axios from 'axios'

import { getAppConfig } from 'config'

import type { NotificationTemplate } from './types'

const NOTIFICATION_TEMPLATES = '/notification-templates'
const MANUAL_TRIGGER = '/manual-trigger'

export const getNotifications = async () => {
  try {
    const resp = await axios.get<NotificationTemplate[]>(`${getAppConfig().apiHost}${NOTIFICATION_TEMPLATES}`)
    return resp.data
  } catch (er) {
    console.log(er)
    throw er
  }
}

export const updateNotification = async (template: NotificationTemplate) => {
  try {
    const resp = await axios.put<NotificationTemplate>(`${getAppConfig().apiHost}${NOTIFICATION_TEMPLATES}`, template)
    return resp.data
  } catch (er) {
    console.log(er)
    throw er
  }
}

export const sendNotifications = async () => {
  try {
    const resp = await axios.post(`${getAppConfig().apiHost}${MANUAL_TRIGGER}`)
    return resp.data
  } catch (er) {
    console.log(er)
    throw er
  }
}
