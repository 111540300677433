import axios from 'axios'

import { getAppConfig } from 'config'

import type { GooglePerson } from './types'

const ADMIN = '/admins'

export const getAdmins = async () => {
  try {
    const resp = await axios.get<GooglePerson[]>(`${getAppConfig().apiHost}${ADMIN}`)
    return resp.data
  } catch (er) {
    console.log(er)
    throw er
  }
}

export const removeAdmin = async (emailAddress: string) => {
  try {
    const resp = await axios.delete(`${getAppConfig().apiHost}${ADMIN}`, { data: { emailAddress } })
    return resp.data
  } catch (er) {
    console.log(er)
    throw er
  }
}

export const addAdmin = async (emailAddress: string) => {
  try {
    const resp = await axios.post(`${getAppConfig().apiHost}${ADMIN}`, { emailAddress })
    return resp.data
  } catch (er) {
    console.log(er)
    throw er
  }
}
