import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Avatar, Box, Button, Typography } from '@mui/material'

import { searchDirectory } from 'api/googlePeopleHelpers'
import type { GooglePerson } from 'api/types'
import type { AppDispatch } from 'store'
import { profileSelector } from 'store/selectors'
import { removeAdminAction } from 'store/slices/adminsSlice'

interface AdminItemProps {
  admin: GooglePerson
}

export const AdminItem = ({ admin }: AdminItemProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const [person, setPerson] = useState<undefined | GooglePerson>()

  useEffect(() => {
    searchDirectory(admin.email)
      .then(p => {
        if (p.length === 1) {
          setPerson(p[0])
        }
      })
      .catch(err => console.error(err))
  }, [admin.email])

  const profile = useSelector(profileSelector)

  const remove = () => {
    if (confirm(`Are you sure that you want to remove ${admin.email} from admins?`)) {
      profile && profile.email !== admin.email && dispatch(removeAdminAction(admin.email))
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        marginBottom: '2rem'
      }}
    >
      <Avatar src={person?.photo}>{admin.email[0].toUpperCase()}</Avatar>
      <Typography>{admin.email}</Typography>
      <Box sx={{ flex: 1 }} />
      {profile && profile.email !== admin.email && (
        <Button onClick={remove} color="error" variant="contained">
          Remove Admin
        </Button>
      )}
    </Box>
  )
}
