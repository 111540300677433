import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ACTION_LOGOUT } from './constants'
import type { GoogleProfileData, GoogleTokenData } from './types'

const SLICE = 'profile'

export interface ProfileState {
  profileData?: GoogleProfileData
  tokenData?: GoogleTokenData
}

const initialState: ProfileState = {
  profileData: undefined,
  tokenData: undefined
}

export const logoutAction = createAction(ACTION_LOGOUT)

const profileSlice = createSlice({
  name: SLICE,
  initialState,
  reducers: {
    loginAction(
      state,
      action: PayloadAction<{
        profile: GoogleProfileData
        token: GoogleTokenData
      }>
    ) {
      state.profileData = action.payload.profile
      state.tokenData = action.payload.token
    }
  },
  extraReducers: builder => {
    builder.addCase(ACTION_LOGOUT, () => {
      return { ...initialState }
    })
  }
})

export const profileReducer = profileSlice.reducer
export const { loginAction } = profileSlice.actions
