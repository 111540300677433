import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getAdmins, addAdmin, removeAdmin } from 'api/admins'
import type { GooglePerson } from 'api/types'
import type { RootState } from 'store'

import { ACTION_LOGOUT } from './constants'
import type { ApiResponse } from './types'

const SLICE = 'admins'

const ACTION_GET_ADMINS = SLICE + '/get-all'
const ACTION_ADD_ADMIN = SLICE + '/add'
const ACTION_REMOVE_ADMIN = SLICE + '/remove'

export interface AdminsState {
  admins: ApiResponse<GooglePerson[]>
}

const initialState: AdminsState = {
  admins: {
    loading: false
  }
}

export const getAdminsAction = createAsyncThunk(ACTION_GET_ADMINS, async () => {
  return getAdmins()
})

export const addAdminAction = createAsyncThunk<GooglePerson[], GooglePerson, { state: RootState }>(
  ACTION_ADD_ADMIN,
  async (person, thunkApi) => {
    try {
      await addAdmin(person.email)
      return getAdmins()
    } catch (err) {
      console.error(err)
    }

    return Promise.resolve([...(thunkApi.getState().admins.admins.data ?? [])])
  }
)

export const removeAdminAction = createAsyncThunk<GooglePerson[], string, { state: RootState }>(
  ACTION_REMOVE_ADMIN,
  async (email, thunkApi) => {
    try {
      await removeAdmin(email)
      return getAdmins()
    } catch (err) {
      console.error(err)
    }
    return Promise.resolve([...(thunkApi.getState().admins.admins.data ?? [])])
  }
)

const adminsSlice = createSlice({
  name: SLICE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    // fetch contractors
    builder.addCase(getAdminsAction.pending, (state, action) => {
      state.admins = {
        data: undefined,
        error: undefined,
        loading: true
      }
    })
    builder.addCase(getAdminsAction.fulfilled, (state, action) => {
      state.admins = {
        data: action.payload,
        error: undefined,
        loading: false
      }
    })
    builder.addCase(getAdminsAction.rejected, (state, action) => {
      state.admins = {
        data: undefined,
        error: 'Failed to load',
        loading: false
      }
    })

    // add & remove - simple impl
    builder.addCase(addAdminAction.fulfilled, (state, action) => {
      state.admins = {
        data: action.payload,
        error: undefined,
        loading: false
      }
    })

    builder.addCase(removeAdminAction.fulfilled, (state, action) => {
      state.admins = {
        data: action.payload,
        error: undefined,
        loading: false
      }
    })

    builder.addCase(ACTION_LOGOUT, () => {
      return { ...initialState }
    })
  }
})

export const adminsReducer = adminsSlice.reducer
