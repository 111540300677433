import { Page } from '../Page'
import { PageWrap } from '../Page/PageWrap'

import { NotificationPageContent } from './NotificationPageContent'

export const PageNotificationManagement = () => {
  return (
    <Page>
      <PageWrap>
        <NotificationPageContent />
      </PageWrap>
    </Page>
  )
}
