import { Link } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { ROUTES } from './constants'

export const NoContent = () => (
  <Box>
    <Typography variant="h2">Nothing here</Typography>
    <Typography>
      <Link to={ROUTES.HOME}>Go home</Link>
    </Typography>
  </Box>
)
