import { gapi } from 'gapi-script'

import type { GooglePerson } from './types'

// see more at
// https://developers.google.com/people/quickstart/js

export const searchDirectory = async (search: string): Promise<GooglePerson[]> => {
  const token = gapi.auth.getToken()

  const res = await gapi.client.people.people.searchDirectoryPeople({
    access_token: token.access_token,
    mergeSources: 'DIRECTORY_MERGE_SOURCE_TYPE_CONTACT',
    pageSize: 25,
    query: search,
    readMask: 'emailAddresses,photos',
    sources: 'DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE'
  })

  const people = res.result.people

  return (people ?? []).map(person => ({
    email: person.emailAddresses?.find(addrItem => addrItem.metadata?.primary)?.value ?? '',
    photo: person.photos?.[0]?.url
  }))
}
