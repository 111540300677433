import { useState } from 'react'
import { useGoogleLogout } from 'react-google-login'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { AppBar, Avatar, Box, IconButton, Menu, MenuItem, Toolbar, Typography, styled } from '@mui/material'

import { Colors } from 'components/theme'
import { getAppConfig } from 'config'
import logo from 'icons/logo.png'
import { ROUTES } from 'router/constants'
import { profileSelector } from 'store/selectors'
import { logoutAction } from 'store/slices/profileSlice'
import type { GoogleProfileData } from 'store/slices/types'

const Logo = styled('img')({
  width: '3.6rem',
  height: '3.6rem'
})

const getInitials = (profile?: GoogleProfileData) => {
  if (profile?.givenName || profile?.familyName) {
    return `${profile?.givenName?.[0]?.toUpperCase() ?? ''}${profile?.familyName?.[0]?.toUpperCase() ?? ''}`
  }

  return profile?.email?.[0]?.toUpperCase() ?? '?'
}

export const Header = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const dispatch = useDispatch()

  const onLogoutSuccess = () => {
    dispatch(logoutAction())
  }

  const onFailure = () => {
    console.error('LOGOUT FAILED')
  }

  const { signOut, loaded } = useGoogleLogout({
    clientId: getAppConfig().googleClientId,
    onLogoutSuccess,
    onFailure
  })

  const profile = useSelector(profileSelector)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    setAnchorElUser(null)
    signOut()
  }

  return (
    <AppBar position="static" sx={{ p: 0 }}>
      <Toolbar>
        <Link to={ROUTES.CONTRACTORS_LIST}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Logo src={logo} alt="JNA" />
            <Typography
              variant="h6"
              noWrap
              sx={{
                marginLeft: '1rem',
                fontFamily: 'monospace',
                fontWeight: 700,
                fontSize: '2.4rem',
                letterSpacing: '.3rem',
                color: Colors.BACKGROUND,
                textDecoration: 'none'
              }}
            >
              Jira Notifications Automation
            </Typography>
          </Box>
        </Link>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ flexGrow: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ color: Colors.BACKGROUND, marginRight: '1rem' }}>
            {profile?.name ?? profile?.email}
          </Typography>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar src={profile?.imageUrl} sx={{ backgroundColor: Colors.AGILE_ORANGE, color: Colors.BACKGROUND }}>
              {getInitials(profile)}
            </Avatar>
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem disabled={!loaded} onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
