import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { isLoggedInSelector } from 'store/selectors'

import { ROUTES } from './constants'

export const AnonymousOnly = ({ children }: { children: React.ReactNode }) => {
  const isLoggedIn = useSelector(isLoggedInSelector)

  if (isLoggedIn) {
    return <Navigate to={ROUTES.CONTRACTORS_LIST} />
  }

  return <>{children}</>
}
