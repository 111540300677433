import { Page } from '../Page'
import { PageWrap } from '../Page/PageWrap'

import { AdminsPageContent } from './AdminsPageContent'

export const PageAdmins = () => {
  return (
    <Page>
      <PageWrap>
        <AdminsPageContent />
      </PageWrap>
    </Page>
  )
}
