import { SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Tab, Tabs, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { NotificationTemplate } from 'api/types'
import { TabPanel } from 'components/TabPanel'
import type { AppDispatch } from 'store'
import { notificationsSelector } from 'store/selectors'
import { getNotificationsAction } from 'store/slices/notificationSlice'

import { NotificationTemplateEdit } from './NotificationTemplateEdit'

export const NotificationPageContent = () => {
  const { loading, data } = useSelector(notificationsSelector)
  const [value, setValue] = useState(0)
  const dispatch = useDispatch<AppDispatch>()

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (!loading && !data) {
      dispatch(getNotificationsAction())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data])

  if (loading) {
    return (
      <Box sx={{ paddingTop: '2rm' }}>
        <Typography sx={{ margin: 'auto', width: 'fit-content' }}>Loading</Typography>
      </Box>
    )
  }
  const sortTabs = (a: NotificationTemplate, b: NotificationTemplate) => {
    return a?.subject > b?.subject ? 1 : -1
  }
  const list = [...(data ?? [])].sort(sortTabs).filter(tab => tab?.subject)

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          (
          {list.map(n => (
            <Tab label={n.subject} key={n.key} />
          ))}
        </Tabs>
      </Box>
      {list.map((n, idx) => (
        <TabPanel value={value} index={idx} key={n.key}>
          <NotificationTemplateEdit notification={n} />
        </TabPanel>
      ))}
    </Box>
  )
}
