import { Box, styled } from '@mui/material'

import { Header } from './Header'
import { SideMenu } from './SideMenu'

interface PageProps {
  children?: React.ReactNode
}

const PageBox = styled(Box)({
  minHeight: '100vh',
  minWidth: '100vw',
  display: 'flex',
  flexDirection: 'column'
})

export const Page = ({ children }: PageProps) => {
  return (
    <PageBox>
      <Header />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1
        }}
      >
        <SideMenu />
        <Box sx={{ flex: 1 }}>{children}</Box>
      </Box>
    </PageBox>
  )
}
