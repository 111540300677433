import { Box } from '@mui/material'

interface PageWrapProps {
  children?: React.ReactNode
}

export const PageWrap = ({ children }: PageWrapProps) => (
  <Box
    sx={{
      margin: '2rem 2rem 0 2rem'
    }}
  >
    {children}
  </Box>
)
