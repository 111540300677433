import { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material'
import { parseExpression } from 'cron-parser'

interface CronEditProps {
  disabled: boolean
  initialCron: string
  handleSaveCron: (value: string) => void
}

export const CronEdit = ({ disabled, initialCron, handleSaveCron }: CronEditProps) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(initialCron)

  const [valid, setValid] = useState(false)
  const [nextDates, setNextDates] = useState<string[]>([])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSave = () => {
    handleSaveCron(value)
    setOpen(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    try {
      const cr = parseExpression(value)
      setValid(true)

      setNextDates([
        cr.next().toDate().toISOString(),
        cr.next().toDate().toISOString(),
        cr.next().toDate().toISOString(),
        cr.next().toDate().toISOString(),
        cr.next().toDate().toISOString()
      ])
    } catch {
      setValid(false)
      setNextDates([])
    }
  }, [value])

  return (
    <>
      <Button disabled={disabled} variant="contained" onClick={handleClickOpen}>
        Edit Schedule
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit notification schedule</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Use controls below to edit notification schedule. Consider using a good tool to create an expression, like{' '}
            <a
              style={{ color: '#1a73e8' }}
              href="https://www.programmertools.online/generator/cron_expression.html"
              target="_blank"
              rel="noreferrer"
            >
              this one
            </a>
            .
            <br />
            Cron expression should be without year.
          </DialogContentText>
          <TextField
            error={!valid}
            sx={{ margin: '2rem 0' }}
            id="cron"
            label="Cron to edit"
            value={value}
            onChange={handleChange}
          />

          <Typography variant="h5">Next launches:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {nextDates.map(n => (
              <Typography key={n}>{n}</Typography>
            ))}
            {!valid && <Typography>Invalid cron expression</Typography>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={!valid} onClick={handleSave}>
            Save
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
