import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Box, Button, TextField, Typography } from '@mui/material'

import { sendNotifications } from 'api/notifications'
import { getNextScheduleDate, updateSchedule } from 'api/schedulers'
import type { NotificationTemplate } from 'api/types'
import type { AppDispatch } from 'store'
import { updateNotificationAction } from 'store/slices/notificationSlice'

import { CronEdit } from './CronEdit'

interface NotificationTemplateEditProps {
  notification: NotificationTemplate
}

export const NotificationTemplateEdit = ({ notification }: NotificationTemplateEditProps) => {
  const [template, setTemplate] = useState(notification.template)
  const [updated, setUpdated] = useState(false)
  const [pending, setPending] = useState(false)
  const [nextScheduledDate, setNextScheduledDate] = useState('')

  const dispatch = useDispatch<AppDispatch>()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplate(event.target.value)
    setUpdated(true)
  }

  const handleSave = () => {
    setPending(true)
    dispatch(
      updateNotificationAction({
        ...notification,
        template
      })
    ).then(() => {
      setPending(false)
      setUpdated(false)
    })
  }

  const handleSaveCron = async (cron: string) => {
    setPending(true)
    await updateSchedule(cron).catch(err => {
      console.error(err)
      setPending(false)
      setUpdated(false)
    })
    setPending(false)
    setUpdated(false)
  }

  const handleSendNotifications = async () => {
    setPending(true)
    await sendNotifications().catch(err => {
      console.error(err)
      setPending(false)
      setUpdated(false)
    })
    setPending(false)
    setUpdated(false)
    }

  useEffect(() => {
    getNextScheduleDate()
      .then(data => setNextScheduledDate(data.nextDate))
      .catch(err => console.error(err))
  }, [pending])

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '2rem', alignItems: 'center' }}>
        <Button onClick={ () => { handleSendNotifications() }} disabled={pending} variant="contained">
                    Send Now
            </Button>
        <Button onClick={handleSave} variant="contained" color="success" disabled={!updated || pending}>
          Save changes
        </Button>
        {pending && <Typography sx={{ marginLeft: '2rem' }}>Updating...</Typography>}
        <Box sx={{ flex: 1 }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            alignItems: 'center'
          }}
        >
          <Typography>
            Next Scheduled Date:{' '}
            <b>{new Date(nextScheduledDate).toLocaleString(undefined, { timeZoneName: 'short' })}</b>
          </Typography>
          <CronEdit
            disabled={pending}
            initialCron={notification.schedule}
            handleSaveCron={cron => {
              handleSaveCron(cron)
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', minHeight: '50rem' }}>
        <Box sx={{ flex: 1 }}>
          <TextField
            id="outlined-multiline-flexible"
            label="HTML template"
            multiline
            value={template}
            onChange={handleChange}
            sx={{
              height: '100%',
              width: '100%',
              padding: '1rem'
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            padding: '1rem',
            margin: '1rem',
            border: '1px solid black',
            borderRadius: '0.2rem'
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: template }} />
        </Box>
      </Box>
    </Box>
  )
}
