import { createTheme } from '@mui/material'

export enum Colors {
  PRIMARY = '#18191A',
  BACKGROUND = '#F2F2F2',
  SECONDARY = '#aaa',
  AGILE_ORANGE = '#ff7a00'
}

export const theme = createTheme({
  components: {
    MuiToolbar: {
      styleOverrides: {
        regular: {
          backgroundColor: Colors.SECONDARY
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: '10px'
        },
        body: {
          lineHeight: 1.15,
          minHeight: '100vh',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          fontFamily: "'Nunito Sans', sans-serif",
          color: Colors.PRIMARY,
          fontSize: '1.6rem',
          fontWeight: 300,
          margin: 0
        },
        button: {
          fontFamily: 'Nunito Sans, sans-serif'
        },
        a: {
          textDecoration: 'none',
          color: Colors.PRIMARY
        },
        '#root': {
          minWidth: '100vw',
          minHeight: '100vh'
        },
        '.MuiDataGrid-root': {
          '.MuiTablePagination-select': {
            fontSize: '1.4rem',
            padding: '1.4rem 0 0.5rem'
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          fontSize: '1.8rem'
        }
      }
    }
  },
  typography: {
    allVariants: {
      color: Colors.PRIMARY
    },
    h1: {
      fontSize: '3.2rem',
      fontWeight: 400
    },
    h2: {
      fontSize: '2.8rem',
      fontWeight: 400
    },
    h3: {
      fontSize: '2.6rem',
      fontWeight: 400
    },
    h4: {
      fontSize: '2.4rem',
      fontWeight: 400
    },
    h5: {
      fontSize: '2rem',
      fontWeight: 400,
      lineHeight: '2.4rem'
    },
    h6: {
      fontSize: '1.6rem',
      fontWeight: 700
    },
    body1: {
      fontWeight: 300,
      fontSize: '1.6rem'
    },
    body2: {
      fontWeight: 300,
      fontSize: '1.4rem'
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.6rem',
      lineHeight: '2.4rem'
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '1.4rem'
    },
    button: {
      fontWeight: 700,
      fontSize: '1.4rem'
    },
    caption: {
      fontWeight: 700,
      fontSize: '1.2rem',
      lineHeight: '1.6rem'
    },
    overline: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.6rem'
    }
  },

  palette: {
    primary: {
      main: Colors.PRIMARY
    },
    secondary: {
      main: Colors.SECONDARY
    },
    background: {
      default: Colors.BACKGROUND
    }
  }
})
