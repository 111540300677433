import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { adminsReducer } from './slices/adminsSlice'
import { contractorsReducer } from './slices/contractorsSlice'
import { notificationReducer } from './slices/notificationSlice'
import { profileReducer } from './slices/profileSlice'

export const rootReducer = combineReducers({
  profile: profileReducer,
  notification: notificationReducer,
  contractors: contractorsReducer,
  admins: adminsReducer
})

export const store = configureStore({
  reducer: rootReducer
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
