import React, { Suspense } from 'react'

import { Box } from '@mui/material'

import { Page } from '../Page'
import { PageWrap } from '../Page/PageWrap'

const ContractorsPageContent = React.lazy(async () => import('./ContractorsPageContent'))

export const PageContractorsList = () => {
  return (
    <Page>
      <PageWrap>
        <Suspense fallback={<Box>Loading...</Box>}>
          <ContractorsPageContent />
        </Suspense>
      </PageWrap>
    </Page>
  )
}
