import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'
import { useDispatch } from 'react-redux'

import { Box, Paper, Typography } from '@mui/material'

import { Colors } from 'components/theme'
import { getAppConfig } from 'config'
import { loginAction } from 'store/slices/profileSlice'

export const PageLogIn = () => {
  const dispatch = useDispatch()

  const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    const resp = res as GoogleLoginResponse

    // exclude GoogleLoginResponseOffline
    if (resp.profileObj) {
      dispatch(
        loginAction({
          profile: resp.profileObj,
          token: resp.tokenObj
        })
      )
    }
  }

  const onFailure = (err: { error: string }) => {
    console.log('failed:', err.error)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw'
      }}
    >
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
        <Paper
          sx={{
            p: '2rem',
            m: '2rem',
            width: '100%',
            maxWidth: '68rem',
            height: '24rem',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 0,
            alignItems: 'center'
          }}
        >
          <Typography variant="h2" sx={{ marginBottom: '2rem' }}>
            Welcome to JNA!
          </Typography>
          <Box sx={{ marginTop: '2rem', flex: 1 }}>
            <GoogleLogin
              clientId={getAppConfig().googleClientId}
              buttonText="Sign in with AE Google account"
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={'single_host_origin'}
              isSignedIn={true}
            />
          </Box>
          <Typography variant="subtitle2">Use AgileEngine Google account to log in.</Typography>
        </Paper>
      </Box>
      <Box
        sx={{
          flex: 1,
          backgroundColor: Colors.AGILE_ORANGE,
          display: {
            xs: 'none',
            md: 'inherit'
          }
        }}
      />
    </Box>
  )
}
