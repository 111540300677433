import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Box, Button, Typography } from '@mui/material'

import type { GooglePerson } from 'api/types'
import { theme } from 'components/theme'
import { AppDispatch } from 'store'
import { addAdminAction } from 'store/slices/adminsSlice'

import { AdminAutocomplete } from './AdminAutocomplete'

export const AdminAddPanel = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [selected, setSelected] = useState<GooglePerson | null>(null)
  const [pending, setPending] = useState(false)
  const [addingError, setAddingError] = useState(false)
  const [addingSuccess, setAddingSuccess] = useState(false)

  const handleChange = (person: GooglePerson | null) => {
    setSelected(person)
    setAddingError(false)
    setAddingSuccess(false)
  }

  const addAdmin = () => {
    setPending(true)

    selected && dispatch(addAdminAction(selected)).then(res => {
      setPending(false)
      if (res.meta.requestStatus === 'rejected') {
        setAddingError(true)
        setAddingSuccess(true)
      }
      if (res.meta.requestStatus === 'fulfilled') {
        setAddingSuccess(true)
        setSelected(null)
      }
    })
  }

  return (
    <Box sx={{ alignItems: 'center', marginTop: '1rem', display: 'flex', flexDirection: 'row', gap: '2rem' }}>
      <AdminAutocomplete onSelectedChanged={handleChange} />
      <Button onClick={addAdmin} disabled={selected === null || pending} variant="contained">
        Add admin
      </Button>
      {addingSuccess && (
        <Typography
          sx={{
            color: theme.palette.success.main
          }}
        >
          Admin added!
        </Typography>
      )}
      {addingError && (
        <Typography
          sx={{
            color: theme.palette.error.main
          }}
        >
          Failed to add admin
        </Typography>
      )}
    </Box>
  )
}
