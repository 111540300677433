import { Link, useLocation } from 'react-router-dom'

import { List, ListItemButton, ListItemText, styled } from '@mui/material'

import { ROUTES } from 'router/constants'

const items = [
  {
    text: 'Contractors',
    url: ROUTES.CONTRACTORS_LIST
  },
  {
    text: 'Admins',
    url: ROUTES.ADMINS
  },
  {
    text: 'Notification Management',
    url: ROUTES.NOTIFICATION_MANAGEMENT
  }
]

const Aside = styled('aside')(({ theme }) => ({
  background: theme.palette.background.default,
  width: '20rem',
  boxShadow: '4px 0px 4px 0px rgba(0,0,0,0.3)'
}))

export const SideMenu = () => {
  const location = useLocation()

  return (
    <Aside>
      <List component="nav" sx={{ flex: 1 }}>
        {items.map(item => {
          const isHere = location.pathname.includes(item.url)

          const button = (
            <ListItemButton key={item.url} selected={isHere}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          )

          return isHere ? (
            button
          ) : (
            <Link key={item.url} to={item.url}>
              {button}
            </Link>
          )
        })}
      </List>
    </Aside>
  )
}
