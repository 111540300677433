import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { fetchContractors, getContractors, getHistoricalContractors } from 'api/contractors'
import type { Contractor } from 'api/types'

import { ACTION_LOGOUT } from './constants'
import type { ApiResponse } from './types'

const SLICE = 'contractors'

const ACTION_GET_CONTRACTORS = SLICE + '/get-all'
const ACTION_FETCH_CONTRACTORS = SLICE + '/fetch-all'

export interface ContractorsState {
  contractors: ApiResponse<Contractor[]>
}

const initialState: ContractorsState = {
  contractors: {
    loading: false
  }
}

export const getContractorsAction = createAsyncThunk(
  ACTION_GET_CONTRACTORS,
  async ({ start, end }: { start?: string, end?: string }) => {
    if (!start || !end) {
      return getContractors()
    }
    return getHistoricalContractors(start, end)
  }
)

export const fetchContractorsAction = createAsyncThunk(ACTION_FETCH_CONTRACTORS, async () => {
  return fetchContractors()
})

const contractorsSlice = createSlice({
  name: SLICE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    // fetch contractors
    builder.addCase(getContractorsAction.pending, (state, action) => {
      state.contractors = {
        data: undefined,
        error: undefined,
        loading: true
      }
    })
    builder.addCase(getContractorsAction.fulfilled, (state, action) => {
      state.contractors = {
        data: action.payload,
        error: undefined,
        loading: false
      }
    })
    builder.addCase(getContractorsAction.rejected, (state, action) => {
      state.contractors = {
        data: undefined,
        error: 'Failed to load',
        loading: false
      }
    })
    builder.addCase(fetchContractorsAction.pending, (state, action) => {
      state.contractors = {
        data: undefined,
        error: undefined,
        loading: true
      }
    })
    builder.addCase(fetchContractorsAction.fulfilled, (state, action) => {
      state.contractors = {
        data: action.payload,
        error: undefined,
        loading: false
      }
    })
    builder.addCase(fetchContractorsAction.rejected, (state, action) => {
      state.contractors = {
        data: undefined,
        error: 'Failed to load',
        loading: false
      }
    })

    builder.addCase(ACTION_LOGOUT, () => {
      return { ...initialState }
    })
  }
})

export const contractorsReducer = contractorsSlice.reducer
