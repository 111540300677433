import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Typography } from '@mui/material'

import type { AppDispatch } from 'store'
import { adminsSelector } from 'store/selectors'
import { getAdminsAction } from 'store/slices/adminsSlice'

import { AdminAddPanel } from './AdminAddPanel'
import { AdminItem } from './AdminItem'

export const AdminsPageContent = () => {
  const { loading, data } = useSelector(adminsSelector)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (!loading && !data) {
      dispatch(getAdminsAction())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data])

  if (loading) {
    return (
      <Box sx={{ paddingTop: '2rem' }}>
        <Typography sx={{ margin: 'auto', width: 'fit-content' }}>Loading</Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {data?.map(adm => (
          <AdminItem admin={adm} key={adm.email} />
        ))}
      </Box>

      {!loading && <AdminAddPanel />}
    </Box>
  )
}
