import { useState } from 'react'

import { Autocomplete, Avatar, Grid, TextField, Typography } from '@mui/material'
import throttle from 'lodash/throttle'

import { searchDirectory } from 'api/googlePeopleHelpers'
import type { GooglePerson } from 'api/types'

interface AdminAutocompleteProps {
  onSelectedChanged: (selected: GooglePerson | null) => void
}

export const AdminAutocomplete = ({ onSelectedChanged }: AdminAutocompleteProps) => {
  const [value, setValue] = useState<GooglePerson | null>(null)
  // const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<readonly GooglePerson[]>([])

  const inputValueChanged = throttle((newValue: string) => {
    const loader = async () => {
      setOptions(await searchDirectory(newValue))
    }

    loader()
  }, 200)

  return (
    <Autocomplete
      sx={{ minWidth: '40rem' }}
      id="ae-search"
      getOptionLabel={option => option?.email ?? ''}
      isOptionEqualToValue={(opt, val) => opt.email === val.email}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue: GooglePerson | null) => {
        setValue(newValue)
        onSelectedChanged(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        (newInputValue.length > 1) && inputValueChanged(newInputValue)
      }}
      renderInput={params => <TextField {...params} label="Start typing an email" fullWidth />}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Avatar src={option.photo}>{option.email[0]}</Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="body1" color="text.secondary" sx={{ marginLeft: '1rem' }}>
                  {option.email}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}
