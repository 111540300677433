import axios from 'axios'

import { getAppConfig } from 'config'

const SCHEDULERS = '/schedulers'

export const getNextScheduleDate = async () => {
  try {
    const resp = await axios.get<{ nextDate: string }>(`${getAppConfig().apiHost}${SCHEDULERS}`)
    return resp.data
  } catch (er) {
    console.log(er)
    throw er
  }
}

export const updateSchedule = async (cronExpression: string) => {
  try {
    const resp = await axios.put<{ nextDate: string }>(`${getAppConfig().apiHost}${SCHEDULERS}`, { cronExpression })
    return resp.data
  } catch (er) {
    console.log(er)
    throw er
  }
}
