import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getNotifications, updateNotification } from 'api/notifications'
import type { NotificationTemplate } from 'api/types'

import { ACTION_LOGOUT } from './constants'
import type { ApiResponse } from './types'

const SLICE = 'notification'

const ACTION_GET_NOTIFICATIONS = SLICE + '/get-all'
const ACTION_UPDATE_NOTIFICATION = SLICE + '/create'

export interface NotificationState {
  notifications: ApiResponse<NotificationTemplate[]>
}

const initialState: NotificationState = {
  notifications: {
    loading: false
  }
}

export const getNotificationsAction = createAsyncThunk(ACTION_GET_NOTIFICATIONS, async () => {
  return getNotifications()
})

export const updateNotificationAction = createAsyncThunk(ACTION_UPDATE_NOTIFICATION, async (notification: NotificationTemplate) => {
  await updateNotification(notification)
  return notification
})

const notificationSlice = createSlice({
  name: SLICE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    // fetch notifications
    builder.addCase(getNotificationsAction.pending, (state, action) => {
      state.notifications = {
        data: undefined,
        error: undefined,
        loading: true
      }
    })
    builder.addCase(getNotificationsAction.fulfilled, (state, action) => {
      state.notifications = {
        data: action.payload,
        error: undefined,
        loading: false
      }
    })
    builder.addCase(getNotificationsAction.rejected, (state, action) => {
      state.notifications = {
        data: undefined,
        error: 'Failed to load',
        loading: false
      }
    })

    // update notification
    builder.addCase(updateNotificationAction.fulfilled, (state, action) => {
      const targetIdx = (state.notifications.data ?? []).findIndex(el => el.key === action.payload.key)

      if (state.notifications.data && targetIdx > -1) {
        state.notifications.data[targetIdx] = action.payload
      }
    })

    builder.addCase(ACTION_LOGOUT, () => {
      return { ...initialState }
    })
  }
})

export const notificationReducer = notificationSlice.reducer
